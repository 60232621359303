import '../scss/main.scss';

let sectionError = document.querySelector('.section-error');

if (!sectionError) {
    let contactForm = document.querySelector('#form-contact');
    let btnForm = document.querySelector('#btn-form');
    let name = contactForm.querySelector('input[name="name"]');
    let email = contactForm.querySelector('input[name="email"]');
    let message = contactForm.querySelector('textarea[name="message"]');
    //let token = contactForm.querySelector('input[name="csrf_token"]');
    let scrollElems = document.querySelectorAll('.scroll');
    let mobileNav = document.querySelector('.mobile-nav');
    let toggleNavBtn = document.querySelector('#nav-toggle-btn');
    let closeNavBtn = document.querySelector('.mobile-nav__close');

    //MODAL
    let lightbox = document.querySelector('#lightbox');
    let figures = document.querySelectorAll('.figure__img');
    let closeModalBtn = document.querySelector('.modal__close');
    let slides = document.querySelectorAll('.slide');

    //BANNER
    let bannerCounter = document.querySelector('.banner__counter');
    let start = 598967;
    let speed = 3000;

    window.recaptchaCallback = recaptchaCallback;

    document.addEventListener("DOMContentLoaded", (e) => {
        counter();
        setInterval(function () {
            counter();
        }, speed);
    });

    function counter() {
        let res = [];

        start += 1;

        for(let digit of start.toString()) {
            res.push(`<span class="banner__number">${digit}</span>`);
        }
        
        bannerCounter.innerHTML = res.join(" ");
    }

    contactForm.addEventListener('submit', e => {
        e.preventDefault();

        btnForm.textContent = 'ENVIANDO...';

        fetch('/contact', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                name: name.value,
                email: email.value,
                message: message.value,
                captcha: grecaptcha.getResponse()
                //csrf_token: token.value
            }),
        })
            .then(response => response.json())
            .then(response => {
                alert(response.message);
                btnForm.textContent = 'CONTÁCTANOS';
                name.value = '';
                email.value = '';
                message.value = '';
            });
    });

    function recaptchaCallback() {
        btnForm.removeAttribute('disabled');
    };

    // TOGGLE SIDEBAR
    toggleNavBtn.addEventListener('click', e => {
        mobileNav.classList.add('mobile-nav--open');
    });

    closeNavBtn.addEventListener('click', function () {
        mobileNav.classList.remove('mobile-nav--open');
    });

    // SCROLLING
    for (let i = 0; i < scrollElems.length; i++) {
        const elem = scrollElems[i];

        elem.addEventListener('click', function (e) {
            e.preventDefault();

            // 1. Get the element id to which you want to scroll
            const scrollElemId = e.currentTarget.href.split('#')[1];

            // 2. find that node from the document
            const scrollEndElem = document.getElementById(scrollElemId);

            // 3. and well animate to that node.. 
            const anim = requestAnimationFrame((timestamp) => {
                const stamp = timestamp || new Date().getTime();
                const duration = 1200;
                const start = stamp;

                const startScrollOffset = window.pageYOffset;
                const scrollEndElemTop = scrollEndElem.getBoundingClientRect().top;

                scrollToElem(start, stamp, duration, scrollEndElemTop, startScrollOffset);
            })
        })
    }

    const easeInCubic = function (t) { return t * t * t }

    const scrollToElem = (startTime, currentTime, duration, scrollEndElemTop, startScrollOffset) => {
        const runtime = currentTime - startTime;
        let progress = runtime / duration;

        progress = Math.min(progress, 1);

        const ease = easeInCubic(progress);

        window.scroll(0, startScrollOffset + (scrollEndElemTop * ease));
        if (runtime < duration) {
            requestAnimationFrame((timestamp) => {
                const currentTime = timestamp || new Date().getTime();
                scrollToElem(startTime, currentTime, duration, scrollEndElemTop, startScrollOffset);
            });
        }
    }

    for (let i = 0; i < figures.length; i++) {
        const fig = figures[i];

        fig.addEventListener('click', (e) => {
            e.preventDefault();
            lightbox.style.display = 'block';
            //console.log(e.currentTarget.src)
            const slide = document.querySelector(`#slide-${i + 1}`);
            slide.style.display = 'flex';
        });
    }

    closeModalBtn.addEventListener('click',  (e) => {
        lightbox.style.display = 'none';
        for (let i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        };
    });

}


